<template>
	<div class="our-leadership">
        <div>
            <div class="text-center title my-5">
                <img src="@/assets/our-leadership/our-leadership.png" alt="">
                <h1 class="">Our Leadership</h1>
            </div>


            <div class="offset-1 col-10 profiles">
                <b-card no-body>
                    <b-tabs pills card>
                        <b-tab v-for="(leader, leader_index ) in ourLeadership" :key="leader_index" :title="leader.section" :active="leader_index === 0">
                            <b-card-text class="mt-4">
                                <div class="row">
                                    <div class="col-md-4 mb-3 pr-2" v-for="member in leader.members" :key="member.name">
                                        <div class="mx-md-1">
                                            <img :src="member.img" :alt="member.name">
                                            <h4 class="mt-1">{{ member.name }}</h4>
                                            <h5 class="position">{{ member.position }}</h5>
                                            <p v-if="member.info != ''" class="info">{{ member.info }}</p>
                                            <div class='down' v-html='member.down'></div>
                                        </div>
                                    </div>
                                </div>
                            </b-card-text>
                        </b-tab>
                    </b-tabs>
                </b-card>
                
            </div>
        </div>
        <PageNavigation prev='/our-organisation' next='/contributors'/>
	</div>	
</template>

<script>
import Ourleadership from '@/components/our-leadership/ourLeadership.js'
import PageNavigation from '@/components/PageNavigation.vue'

export default {
    name: 'OurLeadership',

    components: {
        PageNavigation
    },

    data(){
        return {
            ourLeadership : Ourleadership
        }
    }
}
</script>

<style scoped>
.card {
    border: none;
}
h5, p {
    white-space: pre-wrap;
    line-height: 1.2 !important;
}

.tab-content .row > div{
    margin: 0;
    margin-left: 12px;
    padding: 0;
}

@media(min-width: 768px) {
    .tab-content .row > div{
        margin-left: 0px;
    }
}
</style>

<style>
.our-leadership {
    margin-top: 120px;
    min-height: 100vh;
}
.down {
    color: #808080;
    font-size: 16px;
}

h1 {
    color: #DA3406;
    font-size: 38px;
    font-family: 'Montserrat SemiBold';
}




.tabs .card-header {
    margin-top: 60px;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    background: none;
    border: none;
    padding: 0;
}
.card-header-pills {
    margin: 0;
}
.tabs .card-body {
    padding: 0;
}

ul[role=tablist] {
    margin-bottom: 50px;
}



a[role=tab] {
    height: 100%;
    align-items: center;
}

.nav-pills .nav-link {
    display: flex;
    justify-content: center;
    background-color: #CCCCCC80;
    color: #808080;
    border-top: 3px solid #80808080;
    border-radius: 0;
    font-size: 18px;
    font-family: 'Montserrat Medium';
    padding:  20px 40px;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    color: #000;
    background-color: white;
    border-top: 3px solid #F05123;

}

li[role=presentation] {
    width: 50%;
}

.tab-content h4 {
    color: #DA3305;
    font-size: 21px;
    font-family: 'Montserrat Semibold';
    margin-top: 20px !important;
}
.tab-content h5 {
    font-size: 18px;
    font-family: 'Montserrat Semibold';
    margin: 0px 0px 10px !important;
    color:  #363636;
}
.tab-content p {
    font-size: 18px;
    font-family: 'Frutiger Neue LT Book';
    color:  #363636;
}

/*mobile */
@media(max-width: 768px) {
    .our-leadership {
        margin-top: 100px;
    }
    .nav-pills .nav-link {
        font-size: 16px;
        font-family: 'Montserrat';
        padding: 10px;
    }
    .card-header {
        margin-top: 20px !important;
    }
    h1 {
        font-size: 24px;
    }

    a[role=tab] {
        text-align: left;
    }
    .content h5 {
        margin-top: 0;
    }
    .tab-content h4 {
        font-size: 18px;
    }
    .tab-content h5,
    .tab-content p {
        font-size: 16px;
    }
}

@media(min-width: 768px) {
    .card-header {
        width: 50%;
    }

    .tab-content .row > div{
        margin-left: 0px;
    }


    .tab-content p {
        line-height: 1;
    }

    .profiles img {
        width: 100%;
    }

}

</style>