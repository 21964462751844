export default [
    {
        "section": "Board of Directors",
        "members": [
            {
                "name": "Dr Gerard Ee",
                "position": "Chairman",
                "info": "",
                "img": require("@/assets/our-leadership/Board_of_Directors/BoD_01_Dr_Gerard_Ee.jpg")
            },
            {
                "name": "Dr Ang Seng Bin",
                "position": "Head & Senior Consultant, Menopause Unit",
                "info": "KK Women’s and Children’s Hospital",
                "img": require("@/assets/our-leadership/Board_of_Directors/BoD_02_Dr_Ang_Seng_Bin.jpg")
            },
            {
                "name": "Dr Benjamin Koh",
                "position": "Deputy Secretary (Development)",
                "info": "Ministry of Health",
                "img": require("@/assets/our-leadership/Board_of_Directors/BoD_03_Dr_Benjamin_Koh.jpg")
            },
            {
                "name": "A/Prof Chin Jing Jih",
                "position": "Chairman, Medical Board",
                "info": "Tan Tock Seng Hospital & Central Health",
                "img": require("@/assets/our-leadership/Board_of_Directors/BoD_04_A_Prof_Chin_Jing_Jih.jpg")
            },
            {
                "name": "Mr Daniel Soh",
                "position": "Board Member",
                "info": "",
                'down':"Stepped down on 31 August 2021",
                "img": require("@/assets/our-leadership/Board_of_Directors/BoD_05_Mr_Daniel_Soh.jpg")
            },
            {
                "name": "Mr Fong Heng Boo",
                "position": "Board Member",
                "info": "",
                "img": require("@/assets/our-leadership/Board_of_Directors/BoD_06_Mr_Fong_Heng_Bee.jpg")
            },
            {
                "name": "Mr Jeyaraj Indra Raj",
                "position": "Partner",
                "info": "Harold Seet & Indra Raj",
                "img": require("@/assets/our-leadership/Board_of_Directors/BoD_07_Mr_Jeyaraj_Indra_Raj.jpg")
            },
            {
                "name": "Mr Kong Eng Huat",
                "position": "Board Member",
                "info": "",
                "img": require("@/assets/our-leadership/Board_of_Directors/BoD_08_Mr_Kong_Eng_Huat.jpg")
            },
            {
                "name": "Dr Lee Tung Jean",
                "position": "Deputy Secretary",
                "info": "Ministry of Social and Family Development",
                "img": require("@/assets/our-leadership/Board_of_Directors/BoD_09_Dr_Lee_Tung_Jean.jpg")
            },
            {
                "name": "Ms Teoh Zsin Woon",
                "position": "Deputy Secretary (Transformation)",
                "info": "Public Service Division, Prime Minister’s Office",
                "img": require("@/assets/our-leadership/Board_of_Directors/BoD_10_Ms_Teoh_Zsin_Woon.jpg")
            },
            {
                "name": "Ms Theresa Goh",
                "position": "Managing Partner",
                "info": "ThreeSixty Partnership",
                "img": require("@/assets/our-leadership/Board_of_Directors/BoD_11_Ms_Theresa_Goh.jpg")
            },
            {
                "name": "Mr William Liu",
                "position": "Chairman and Managing Partner",
                "info": "Stream Global Pte Ltd",
                "img": require("@/assets/our-leadership/Board_of_Directors/BoD_12_Mr_William_Liu.jpg")
            },
            {
                "name": "Mdm Zuraidah Abdullah",
                "position": "Chief Executive Officer",
                "info": "Yayasan MENDAKI",
                "img": require("@/assets/our-leadership/Board_of_Directors/BoD_13_Mdm_Zuraidah_Abdullah.jpg")
            },
        ],
    },
    {
        "section": "Senior Management Team",
        "members": [
            {
                "name": "Mr Tan Kwang Cheak",
                "position": "Chief Executive Officer",
                "img": require("@/assets/our-leadership/Senior_Management_Team/SMT_01_Mr_Tan_Kwang_Cheak.jpg")
            },
            {
                "name": "Mr Noel Cheah",
                "position": "Deputy Chief Executive Officer\nChief",
                "info": "Caregiving and Community Mental Health Division",
                "img": require("@/assets/our-leadership/Senior_Management_Team/SMT_02_Mr_Noel_Cheah.jpg")
            },
            {
                "name": "Mr Chern Siang Jye",
                "position": "Group Chief",
                "info": "Sector and Partnerships Division\nCare System Integration Division",
                "img": require("@/assets/our-leadership/Senior_Management_Team/SMT_03_Mr_Chern_Siang_Jye.jpg")
            },
            {
                "name": "Mr Derek Tan",
                "position": "Chief",
                "info": "Manpower and Talent Division",
                "img": require("@/assets/our-leadership/Senior_Management_Team/SMT_04_Mr_Derek_Tan_Yip_Teck.jpg")
            },
            {
                "name": "Mr Hagen Ong",
                "position": "Chief",
                "info": "Human Resource Division",
                "img": require("@/assets/our-leadership/Senior_Management_Team/SMT_05_Mr_Hagen_Ong.jpg")
            },
            {
                "name": "Ms Heidi Rafman",
                "position": "Chief",
                "info": "Quality and Productivity Division\nHealthy Ageing Department",
                "img": require("@/assets/our-leadership/Senior_Management_Team/SMT_06_Ms_Heidi_Rafman.jpg")
            },
            {
                "name": "Mr Kelvin Lim",
                "position": "Chief",
                "info": "Grants Division",
                "img": require("@/assets/our-leadership/Senior_Management_Team/SMT_07_Mr_Kelvin_Lim.jpg")
            },
            {
                "name": "Mr Ong Yunn Shing",
                "position": "Chief",
                "info": "Strategy, Research and Data Division\nGovernance, Risk and Legal Department",
                "img": require("@/assets/our-leadership/Senior_Management_Team/SMT_08_Mr_Ong_Yunn_Shing.jpg")
            },
            {
                "name": "Mr Sim Tiong Kian",
                "position": "Chief",
                "info": "Silver Generation Division\nCentral Planning Operations Department",
                "img": require("@/assets/our-leadership/Senior_Management_Team/SMT_09_Mr_Sim_Tiong_Kian.jpg")
            },
            {
                "name": "Mr Soh Keng Taan",
                "position": "Chief Information and Digital Officer",
                "info": "IT, Innovation and Digitalisation Division",
                "img": require("@/assets/our-leadership/Senior_Management_Team/SMT_10_Mr_Soh_Keng_Taan.jpg")
            },
            {
                "name": "Ms Winifred Lau",
                "position": "Chief",
                "info": "Primary and Community Care Development Division",
                "img": require("@/assets/our-leadership/Senior_Management_Team/SMT_11_Ms_Winifred_Lau.jpg")
            },
            {
                "name": "Dr Wong Loong Mun",
                "position": "Chief",
                "info": "Care Integration and Operations Division\nCustomer Experience Department",
                "img": require("@/assets/our-leadership/Senior_Management_Team/SMT_12_Mr_Wong_Loong_Mun.jpg")
            },
            {
                "name": "Ms Carol Choi",
                "position": "Deputy Chief Financial Officer",
                "info": "Finance and Procurement Division",
                "img": require("@/assets/our-leadership/Senior_Management_Team/SMT_13_Ms_Carol_Choi.jpg")
            },
            {
                "name": "Ms See Yen Theng",
                "position": "Deputy Chief",
                "info": "Caregiving and Community Mental Health Division",
                "img": require("@/assets/our-leadership/Senior_Management_Team/SMT_14_Ms_See_Yen_Theng.jpg")
            },
            {
                "name": "Mr Andy Seet",
                "position": "Director",
                "info": "Sector and Partnerships Division",
                "img": require("@/assets/our-leadership/Senior_Management_Team/SMT_15_Mr_Andy_Seet.jpg")
            },
            {
                "name": "Mr Eric Chen",
                "position": "Director",
                "info": "Silver Generation Division",
                "img": require("@/assets/our-leadership/Senior_Management_Team/SMT_16_Mr_Eric_Chen.jpg")
            },
            {
                "name": "Mr Gary Ong",
                "position": "Chief Information Security Officer",
                "info": "IT, Innovation and Digitalisation Division",
                "img": require("@/assets/our-leadership/Senior_Management_Team/SMT_17_Mr_Gary_Ong_Chin_Boon.jpg")
            },
            {
                "name": "Mr Goh Dan Yang",
                "position": "Director",
                "info": "Strategy, Research and Data Division\nGovernance, Risk and Legal Department",
                "img": require("@/assets/our-leadership/Senior_Management_Team/SMT_18_Mr_Goh_Dan_Yang.jpg")
            },
            {
                "name": "Mr Henry Kang",
                "position": "Director",
                "info": "IT, Innovation and Digitalisation Division",
                "img": require("@/assets/our-leadership/Senior_Management_Team/SMT_19_Mr_Henry_Kang.jpg")
            },
            {
                "name": "Mr Ho Yik Hwee",
                "position": "Director",
                "info": "IT, Innovation and Digitalisation Division",
                "img": require("@/assets/our-leadership/Senior_Management_Team/SMT_20_Mr_Ho_Yik_Hwee.jpg")
            },
            {
                "name": "Ms Ivy Lok",
                "position": "Director",
                "info": "Care Integration and Operations Division",
                "img": require("@/assets/our-leadership/Senior_Management_Team/SMT_21_Ms_Ivy_Lok.jpg")
            },
            {
                "name": "Ms Karen Lee",
                "position": "Director",
                "info": "Sector and Partnerships Division",
                "img": require("@/assets/our-leadership/Senior_Management_Team/SMT_22_Ms_Karen_Lee_Hwei_Mien.jpg")
            },
            {
                "name": "Mr Mohamed Farouk Bin Mohamed Ismail",
                "position": "Director",
                "info": "Grants Division",
                "img": require("@/assets/our-leadership/Senior_Management_Team/SMT_23_Mr_Mohamed_Farouk_Bin_Mohamed_Ismail.jpg")
            },
            {
                "name": "Ms Rebecca Chong",
                "position": "Director",
                "info": "Caregiving and Community Mental Health Division",
                "img": require("@/assets/our-leadership/Senior_Management_Team/SMT_24_Ms_Rebecca_Chong.jpg")
            },
            {
                "name": "Ms Violet Ng",
                "position": "Director",
                "info": "Strategy, Research and Data Division",
                "img": require("@/assets/our-leadership/Senior_Management_Team/SMT_25_Ms_Violet_Ng.jpg")
            },
            {
                "name": "Ms Yeo Li Li",
                "position": "Director",
                "info": "Grant Policy and Management Department",
                "img": require("@/assets/our-leadership/Senior_Management_Team/SMT_26_Ms_Yeo_Li_Li.jpg")
            },
            {
                "name": "Ms Angeline Thia",
                "position": "Deputy Director",
                "info": "Care System Integration Division",
                "img": require("@/assets/our-leadership/Senior_Management_Team/SMT_27_Ms_Angeline_Thia.jpg")
            },
            {
                "name": "Ms Ann Ng",
                "position": "Deputy Director",
                "info": "Human Resource Division",
                "img": require("@/assets/our-leadership/Senior_Management_Team/SMT_28_Ms_Ann_Ng_Tze_Wee.jpg")
            },
            {
                "name": "Mr Benny Yip",
                "position": "Deputy Director",
                "info": "Grants Division",
                "img": require("@/assets/our-leadership/Senior_Management_Team/SMT_29_Mr_Benny_Yip.jpg")
            },
            {
                "name": "Mr Choo Jui Sheng",
                "position": "Deputy Director",
                "info": "Primary and Community Care Development Division",
                "img": require("@/assets/our-leadership/Senior_Management_Team/SMT_30_Mr_Choo_Jui_Sheng.jpg")
            },
            {
                "name": "Ms Clarice Woon",
                "position": "Deputy Director",
                "info": "Quality and Productivity Division",
                "img": require("@/assets/our-leadership/Senior_Management_Team/SMT_31_Ms_Clarice_Woon_Lai_Lin.jpg")
            },
            {
                "name": "Mr Daren Lau",
                "position": "Deputy Director",
                "info": "Human Resource Division",
                "img": require("@/assets/our-leadership/Senior_Management_Team/SMT_32_Mr_Daren_Lau.jpg")
            },
            {
                "name": "Mr David Wong",
                "position": "Deputy Director",
                "info": "IT, Innovation and Digitalisation Division\n",
                "down": 'Left organisation on 31 August 2021',
                "img": require("@/assets/our-leadership/Senior_Management_Team/SMT_33_Mr_David_Wong.jpg")
            },
            {
                "name": "Ms Dolly Cheng",
                "position": "Deputy Director",
                "info": "Silver Generation Division",
                "img": require("@/assets/our-leadership/Senior_Management_Team/SMT_34_Ms_Dolly_Cheng.jpg")
            },
            {
                "name": "Ms Eunice Wong",
                "position": "Deputy Director",
                "info": "Caregiving and Community Mental Health Division",
                "img": require("@/assets/our-leadership/Senior_Management_Team/SMT_35_Ms_Eunice_Wong.jpg")
            },
            {
                "name": "Ms Eva Lim",
                "position": "Deputy Director",
                "info": "Integrated Communications and Marketing Department",
                "img": require("@/assets/our-leadership/Senior_Management_Team/SMT_36_Ms_Eva_Lim.jpg")
            },
            {
                "name": "Mr Gerald Ng",
                "position": "Deputy Director",
                "info": "IT, Innovation and Digitalisation Division",
                "img": require("@/assets/our-leadership/Senior_Management_Team/SMT_37_Mr_Gerald_Ng.jpg")
            },
            {
                "name": "Ms Ivy Lim",
                "position": "Deputy Director",
                "info": "Caregiving and Community Mental Health Division",
                "img": require("@/assets/our-leadership/Senior_Management_Team/SMT_38_Ms_Ivy_Lim.jpg")
            },
            {
                "name": "Mr James Koh",
                "position": "Deputy Director",
                "info": "Silver Generation Division",
                "img": require("@/assets/our-leadership/Senior_Management_Team/SMT_39_Mr_James_Koh_Kok_Seng.jpg")
            },
            {
                "name": "Ms Joyce Choo",
                "position": "Deputy Director",
                "info": "Customer Experience Department",
                "img": require("@/assets/our-leadership/Senior_Management_Team/SMT_40_Ms_Joyce_Choo_Hui_Lin.jpg")
            },
            {
                "name": "Mr Keith Lee",
                "position": "Deputy Director",
                "info": "Caregiving and Community Mental Health Division",
                "img": require("@/assets/our-leadership/Senior_Management_Team/SMT_41_Mr_Keith_Lee_Wuan_Hong.jpg")
            },
            {
                "name": "Ms Koh Puay Ling",
                "position": "Deputy Director",
                "info": "Central Planning Operations Department",
                "img": require("@/assets/our-leadership/Senior_Management_Team/SMT_42_Ms_Koh_Puay_Ling.jpg")
            },
            {
                "name": "Ms Lai Phui Ching",
                "position": "Deputy Director",
                "info": "Primary and Community Care Development Division",
                "img": require("@/assets/our-leadership/Senior_Management_Team/SMT_43_Lai_Phui_Ching.jpg")
            },
            {
                "name": "Mr Lee Keng Leong",
                "position": "Deputy Director",
                "info": "Silver Generation Division\n",
                "down": "Left organisation on 1 October 2021",
                "img": require("@/assets/our-leadership/Senior_Management_Team/SMT_44_Mr_Lee_Keng_Leong.jpg")
            },
            {
                "name": "Ms Liao Weifen",
                "position": "Deputy Director",
                "info": "Primary and Community Care Development Division",
                "img": require("@/assets/our-leadership/Senior_Management_Team/SMT_45_Ms_Liao_Weifen.jpg")
            },
            {
                "name": "Mr Lim Kwang Kok",
                "position": "Deputy Director",
                "info": "Strategy, Research and Data Division",
                "img": require("@/assets/our-leadership/Senior_Management_Team/SMT_46_Lim_Kwang_Kok.jpg")
            },
            {
                "name": "Ms Linda Chew",
                "position": "Deputy Director",
                "info": "Finance and Procurement Division",
                "img": require("@/assets/our-leadership/Senior_Management_Team/SMT_47_Linda_Chew.jpg")
            },
            {
                "name": "Ms Lok Yoke Har",
                "position": "Deputy Director",
                "info": "IT, Innovation and Digitalisation Division",
                "img": require("@/assets/our-leadership/Senior_Management_Team/SMT_48_Lok_Yoke_Har.jpg")
            },
            {
                "name": "Mr Louis Chui",
                "position": "Deputy Director",
                "info": "Manpower and Talent Division",
                "img": require("@/assets/our-leadership/Senior_Management_Team/SMT_49 Mr_Louis_Chui_Kian_Hong.jpg")
            },
            {
                "name": "Mr Lum Hon Yuen",
                "position": "Deputy Director",
                "info": "Manpower and Talent Division",
                "img": require("@/assets/our-leadership/Senior_Management_Team/SMT_50_Mr_Lum_Hon_Yuen.jpg")
            },
            {
                "name": "Ms Maninderjit Kaur D/O Major Singh",
                "position": "Deputy Director",
                "info": "Engagement and Culture Department",
                "img": require("@/assets/our-leadership/Senior_Management_Team/SMT_51_Ms_Maninderjit_Kaur_DO_Major_Singh.jpg")
            },
            {
                "name": "Mr Martin Thoo",
                "position": "Deputy Director",
                "info": "Silver Generation Division",
                "img": require("@/assets/our-leadership/Senior_Management_Team/SMT_52_Martin_Thoo.jpg")
            },
            {
                "name": "Ms May Low",
                "position": "Deputy Director",
                "info": "Silver Generation Division",
                "img": require("@/assets/our-leadership/Senior_Management_Team/SMT_53_Ms_May_Low.jpg")
            },
            {
                "name": "Ms Radha D/O Sockalingam",
                "position": "Deputy Director",
                "info": "Sector and Partnerships Division",
                "img": require("@/assets/our-leadership/Senior_Management_Team/SMT_54_Ms_Radha_DO_Sockalingam.jpg")
            },
            {
                "name": "Ms Rina Wang",
                "position": "Deputy Director",
                "info": "Finance and Procurement Division",
                "img": require("@/assets/our-leadership/Senior_Management_Team/SMT_55_Ms_Rina_Wang_Yng_Chyi.jpg")
            },
            {
                "name": "Ms Selina Toh",
                "position": "Deputy Director",
                "info": "Manpower and Talent Division",
                "img": require("@/assets/our-leadership/Senior_Management_Team/SMT_56_Ms_Selina_Toh_Sin_Yee.jpg")
            },
            {
                "name": "Ms Susan See",
                "position": "Deputy Director",
                "info": "Silver Generation Division",
                "img": require("@/assets/our-leadership/Senior_Management_Team/SMT_57_Ms_Susan_See.jpg")
            },
            {
                "name": "Ms Wan Chen Kang Graham",
                "position": "Deputy Director",
                "info": "Strategy, Research and Data Division",
                "img": require("@/assets/our-leadership/Senior_Management_Team/SMT_58_Wan_Chen_Kang.jpg")
            },
        ]
    }
]